import React from "react"
import "./coaches-cards.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const CoachesCards = ({ coachesList }) => {
  const [isViewAll, changeView] = React.useState(false)
  React.useEffect(() => changeView(false), [coachesList])
  let hasMoreData = coachesList.length > 9
  return (
    <section className="block-coaches-cards">
      <div className="container">
        <div className="coaches-cards__list">
          {coachesList.map((coaches, key) => {
            return (
              <div
                key={key}
                className={`coaches-cards__item ${
                  key >= 9 && !isViewAll ? "d-none" : ""
                }`}
              >
                <GatsbyImage
                  image={getImage(coaches.coachImage?.localFile)}
                  alt="coach avatar"
                />
                <h3 className="coaches-cards__item__name">
                  {coaches.coachName}
                </h3>
                <div className="coaches-cards__item__coach-info">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: coaches.specialities,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: coaches.motivationalQuote,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: coaches.certifications,
                    }}
                  />
                </div>
              </div>
            )
          })}
          {hasMoreData && (
            <div
              className={`coaches-cards__list__button `}
              onClick={() => changeView(prev => !prev)}
            >
              <button className={`${isViewAll ? "decor-X" : ""}`}>
                {isViewAll ? `X` : `VIEW ALL`}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CoachesCards
