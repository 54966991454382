import {
  Layout,
  Seo,
  Hero,
  FormSendMailchimp,
  Banner,
  ErrorBoundary,
  MindBodyPage,
} from "../../index.js"
import CoachesFilter from "../components/CoachesFilter/CoachesFilter"
import React, { useState } from "react"
import { graphql } from "gatsby"

const OurTeam = ({ data }) => {
  let { heroTitle, heroSubTitle, heroImage } = data.ourTeam.blocks[0].heroBlock
  let coachesFilter = data.ourTeam.blocks[1].repeatTextBlock
  let coachesList = data.ourTeam.blocks[2].coachesBlock.coachesInformations
  let form = data.ourTeam.blocks[3].formBlock
  // SEO data
  let seoTitle = data.ourTeam.seo.title
  let metaDesc = data.ourTeam.seo.metaDesc

  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout>
      <Seo
        title={data.ourTeam.seo?.title}
        description={data.ourTeam.seo?.metaDesc}
        featuredImage={
          data.ourTeam.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      <Hero
        heroImage={heroImage}
        heroTitle={{
          normalTitle: heroTitle,
          strokeTitle: heroSubTitle,
        }}
        color="#ff7f7f"
        className="our-team-hero-customize"
      />
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
      <CoachesFilter coachesFilter={coachesFilter} coachesList={coachesList} />

      <FormSendMailchimp
        form={form}
        formClassName="our-team__form-block"
        buttonClassName="our-team__form__button"
        bgColor={"#ff7f7f"}
      />
    </Layout>
  )
}

export default OurTeam

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    ourTeam: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      id
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfRepeatTextBlock {
          repeatTextBlock {
            blockTitle
            blockSubTitle
            blockContent {
              mainContent
              subContent
            }
          }
        }
        ... on WpAcfCoachesBlock {
          coachesBlock {
            coachesInformations {
              coachName
              coachImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 450
                      height: 650
                      placeholder: BLURRED
                    )
                  }
                }
              }
              motivationalQuote
              specialities
              certifications
            }
          }
        }
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
  }
`
