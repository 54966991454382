import React, { useEffect } from "react"
import AOS from 'aos'
import "aos/dist/aos.css"
import "./coaches-filter.scss"
import CoachesCards from "../CoachesCards/CoachesCards"

const CoachesFilter = ({ coachesList, coachesFilter }) => {
    const [catName, changeCat] = React.useState("")

    const fixedCatsList = coachesFilter.blockContent

    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, []);

    return (
        <section className="block-coaches-filter">
            <div className="container"
                data-aos-offset="100"
                data-aos-delay="10"
                id="block-coaches-filter__container__parent"
            >

                <h2 className="coaches-filter__title"
                    data-aos="fade-up"
                    data-aos-anchor="#block-coaches-filter__container__parent"
                >
                    {coachesFilter.blockTitle}
                </h2>
                <p className="coaches-filter__subtitle"
                    data-aos="fade-up"
                    data-aos-anchor="#block-coaches-filter__container__parent"
                >
                    {coachesFilter.blockSubTitle}
                </p>

                <div className="coaches-filter__coaches-categories coaches-filter__coaches-categories__d-flex">
                    {fixedCatsList.map((fixedcatName, key) =>
                        <div key={key}
                            className={`coaches-filter__coaches-categories__item coaches-filter__coaches-categories__d-flex__item `}
                            onClick={() => changeCat((prevCatName) => prevCatName === fixedcatName.mainContent ? "" : fixedcatName.mainContent)}>
                            <p>{fixedcatName.mainContent}</p>
                            <div className={`coaches-filter__coaches-categories__item__desc ${fixedcatName.mainContent === catName ? "display" : ""} `}>
                                <p className="coaches-filter__coaches-categories__item__desc__name">{fixedcatName.mainContent}</p>
                                <p className="coaches-filter__coaches-categories__item__desc__text">{fixedcatName.subContent}</p>
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <CoachesCards coachesList={coachesList} />
        </section>
    )
}

export default CoachesFilter

